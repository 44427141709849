<template>
  <div>
    <VRadioGroup class="my-0" @change="onInput" :value="values" hide-details :disabled="readonly">
      <VRadio v-for="{ text, value } in options" :label="text" :value="value" :key="value" />
    </VRadioGroup>
  </div>
</template>

<script>
import {get, keyBy, map, head} from 'lodash-es';

export default {
  name: 'RadioListField',
  props: {
    value: { type: Array },
    options: { type: Array },
    readonly: { type: Boolean },
  },
  computed: {
    values() {
      return head(map(this.value, 'value'));
    },
  },
  methods: {
    onInput(value) {
      const current = keyBy(this.value, 'value');
      this.$emit('input', map([value], (value) => get(current, value, { date: '', id: '', type: 'RADIO', value})));
    },
  }
}
</script>

<style module lang="scss">

</style>
