<template>
  <CounterpartyCard label="Информация о контрагенте">
    <VRow>
      <VCol v-for="({label, value}, index) in info" cols="12" md="3" :key="index">
        <StaticField :label="label" :value="value" />
      </VCol>
    </VRow>
  </CounterpartyCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { ROLES } from '@/store/user/enums';
import StaticField from '@/components/general/StaticField/StaticField';
import CounterpartyCard from '@/components/pkomain/CounterpartyCard/CounterpartyCard';
export default {
  name: 'CounterpartyInfo',
  components: {
    StaticField,
    CounterpartyCard,
  },
  props: {
    value: { type: Object },
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
    info() {
      return [
        {label: 'Статус', value: this.value.status.text},
        {label: 'Дата окончания предквалификации', value: this.value.dateExpire},
        {label: 'Предприятие ЕВРАЗ', value: this.value.company},
        {label: 'ИНН', value: this.value.inn},
        {label: 'Организация', value: this.value.name},
        {label: 'Юр. адрес', value: this.value.address},
        {label: 'ФИО контактного лица', value: this.value.fio},
        {label: 'Email контактного лица', value: this.value.email},
        {label: 'Телефон контактного лица', value: this.value.phone},
        {label: 'ФИО руководителя', value: this.value.firstGeneralContact.fio},
        {label: 'Должность руководителя', value: this.value.firstGeneralContact.position},
        {label: 'E-mail руководителя', value: this.value.firstGeneralContact.email},
        {label: 'Телефон руководителя', value: this.value.firstGeneralContact.phone},
      ]
    },
  }
}
</script>

<style module lang="scss">

</style>
