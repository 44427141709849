<template>
  <div :class="$style.root">
    <VStepper alt-labels non-linear elevation="0">
      <VStepperHeader>
        <VDivider />
        <template v-for="({name, status, code, reviewer, date}, index) in items">
          <VDivider v-if="!!index" :key="index + code" />
          <template v-if="code === STATUSES.NO">
            <VStepperStep :step="index" error-icon="mdi-close" :rules="[() => false]" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === STATUSES.REWORK">
            <VStepperStep :step="index" complete-icon="mdi-pencil-outline" complete :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === STATUSES.YES">
            <VStepperStep :step="index" complete color="success" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === STATUSES.WAIT_IN_LINE">
            <VStepperStep :step="index" complete-icon="mdi-clock-outline" complete color="rgba(0, 0, 0, 0.1)" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
          <template v-if="code === STATUSES.WAIT_FOR_DECISION">
            <VStepperStep :step="index" complete-icon="mdi-clock-outline" complete color="rgba(0, 0, 0, 0.38)" :key="index">
              <div class="text-center">{{name}}</div>
              <small class="mt-2">{{status}}</small>
              <div class="mt-2" v-if="(isReviewer || isSpectator) && reviewer">
                <small>{{reviewer}} {{date}}</small>
              </div>
            </VStepperStep>
          </template>
        </template>
        <VDivider />
      </VStepperHeader>
    </VStepper>
  </div>
</template>

<script>
import { STATUSES } from '@/store/pkomain/enums';
import {mapGetters} from "vuex";
import {ROLES} from "@/store/user/enums";

export default {
  name: 'Stepper',
  props: {
    items: { type: Array },
  },
  data() {
    return {
      STATUSES,
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
  }
}
</script>

<style module lang="scss">
.root {}
</style>
