<template>
  <div :class="$style.root">
    <template v-if="!Object.keys(rejections).length || rejections['BOOL'][0]['value'] !== '1'">
      <template v-if="type === 'BOOL'">
        <VRow dense>
          <VCol v-for="(field, index) in values['BOOL']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
            </template>
            <template v-else>
              <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
                <VRadio label="Да" :value="'1'" />
                <VRadio label="Нет" :value="'0'" />
              </VRadioGroup>
            </template>
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'TEXT'">
        <VRow dense>
          <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" />
            </template>
            <template v-else>
              <VTextarea v-model="field.value" :rules="field.required ? rules.required : null" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'LIST'">
        <VRow dense>
          <VCol v-if="multiple" cols="12" md="6">
            <SelectMultiple v-model="values['LIST']" :options="options" :readonly="readonly" />
          </VCol>
          <template v-else>
            <VCol v-for="(field, index) in values['LIST']" cols="12" md="6" :key="field.type + index">
              <template v-if="field.readonly || readonly">
                <StaticField :value="field.value" :items="options" />
              </template>
              <template v-else>
                <VSelect v-model="field.value" :items="options" outlined dense hideDetails="auto" />
              </template>
            </VCol>
          </template>
        </VRow>
      </template>
      <template v-if="type === 'FILE'">
        <VRow dense>
          <VCol cols="12">
            <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :showCreateDate="readonly" add-file-label="Добавить файл .pdf" />
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'CHECKBOX'">
        <VRow dense>
          <VCol cols="12" md="6">
            <CheckboxListField v-model="values['CHECKBOX']" :options="options" :readonly="readonly" />
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'RADIO'">
        <VRow dense>
          <VCol cols="12" md="6">
            <RadioListField v-model="values['RADIO']" :options="options" :readonly="readonly" />
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'TEXT_WITH_FILE'">
        <VRow dense>
          <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" />
            </template>
            <template v-else>
              <VTextarea v-model="field.value" outlined dense auto-grow rows="1" hideDetails="auto" />
            </template>
          </VCol>
          <VCol cols="12" md="6">
            <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :showCreateDate="readonly" add-file-label="Добавить файл .pdf" />
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'LIST_WITH_FILE'">
        <VRow dense>
          <VCol v-if="multiple" cols="12" md="6">
            <SelectMultiple v-model="values['LIST']" :options="options" :readonly="readonly" />
          </VCol>
          <template v-else>
            <VCol v-for="(field, index) in values['LIST']" cols="12" md="6" :key="field.type + index">
              <template v-if="field.readonly || readonly">
                <StaticField :value="field.value" :items="options" />
              </template>
              <template v-else>
                <VSelect v-model="field.value" :items="options" outlined dense hideDetails="auto" />
              </template>
            </VCol>
          </template>
          <VCol cols="12" md="6">
            <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :showCreateDate="readonly" add-file-label="Добавить файл .pdf" />
          </VCol>
        </VRow>
      </template>
      <template v-if="type === 'BOOL_WITH_TEXT'">
        <VRow dense>
          <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
            </template>
            <template v-else>
              <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
                <VRadio label="Да" :value="'1'" />
                <VRadio label="Нет" :value="'0'" />
              </VRadioGroup>
            </template>
          </VCol>
          <template v-if="values['BOOL'][0]['value'] === '1'">
            <VCol v-for="(field, index) in values['TEXT']" cols="12" :key="field.type + index">
              <div class="pt-3">
                <template v-if="field.readonly || readonly">
                  <StaticField :value="field.value" />
                </template>
                <template v-else>
                  <VTextarea v-model="field.value" outlined dense auto-grow rows="1" hideDetails="auto" />
                </template>
              </div>
            </VCol>
          </template>
        </VRow>
      </template>
      <template v-if="type === 'BOOL_WITH_FILE'">
        <VRow dense>
          <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
            </template>
            <template v-else>
              <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
                <VRadio label="Да" :value="'1'" />
                <VRadio label="Нет" :value="'0'" />
              </VRadioGroup>
            </template>
          </VCol>
          <template v-if="values['BOOL'][0]['value'] === '1'">
            <VCol cols="12">
              <div class="pt-3">
                <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :showCreateDate="readonly" add-file-label="Добавить файл .pdf" />
              </div>
            </VCol>
          </template>
        </VRow>
      </template>
      <template v-if="type === 'BOOL_WITH_TEXT_WITH_FILE'">
        <VRow dense>
          <VCol v-for="(field, index) in values['BOOL']" cols="12" :key="field.type + index">
            <template v-if="field.readonly || readonly">
              <StaticField :value="field.value" :items="[{value: '1', text: 'Да'}, {value: '0', text: 'Нет'}]" />
            </template>
            <template v-else>
              <VRadioGroup v-model="field.value" row dense class="mt-0" hideDetails="auto">
                <VRadio label="Да" :value="'1'" />
                <VRadio label="Нет" :value="'0'" />
              </VRadioGroup>
            </template>
          </VCol>
          <template v-if="values['BOOL'][0]['value'] === '1'">
            <VCol v-for="(field, index) in values['TEXT']" cols="12" md="6" :key="field.type + index">
              <div class="pt-3">
                <template v-if="field.readonly || readonly">
                  <StaticField :value="field.value" />
                </template>
                <template v-else>
                  <VTextarea v-model="field.value" outlined dense auto-grow rows="1" hideDetails="auto" />
                </template>
              </div>
            </VCol>
            <VCol cols="12" md="6">
              <div class="pt-3">
                <FileFieldMultiple v-model="values['FILE']" :readonly="readonly" :showCreateDate="readonly" add-file-label="Добавить файл .pdf" />
              </div>
            </VCol>
          </template>
        </VRow>
      </template>
      <template v-if="type === 'TABLE_CAR'">
        <TableCarField v-model="values['TABLE_CAR']" :code="code" :readonly="readonly" :viewed="viewed" @question-viewed="onViewed" />
      </template>
      <template v-if="type === 'TABLE_DRIVER'">
        <TableDriverField v-model="values['TABLE_DRIVER']" :code="code" :readonly="readonly" :viewed="viewed" @question-viewed="onViewed" />
      </template>
    </template>
    <VRow dense v-if="Object.keys(rejections).length">
      <template v-if="rejections['BOOL'][0]['value'] === '1'">
        <VCol v-for="(field, index) in rejections['TEXT']" cols="12" :key="field.type + index">
          <template v-if="field.readonly || readonly">
            <StaticField label="Отказался от ответа по причине:" :value="field.value" />
          </template>
          <template v-else>
            <VTextarea v-model="field.value" label="Отказываюсь от ответа по причине" outlined dense auto-grow rows="1" hideDetails="auto" />
          </template>
        </VCol>
      </template>
      <template v-if="isContragent && !readonly">
        <VCol v-for="(field, index) in rejections['BOOL']" cols="12" :key="field.type + index">
          <VBtn depressed small color="light" @click="field.value = field.value === '1' ? '0': '1'">
            {{field.value === '1' ? 'Дать ответ': 'Отказаться от ответа'}}
          </VBtn>
        </VCol>
      </template>
    </VRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import StaticField from '@/components/general/StaticField/StaticField';
import SelectMultiple from '@/components/general/SelectMultiple/SelectMultiple';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
import RadioListField from '@/components/general/RadioListField/RadioListField';
import CheckboxListField from '@/components/general/CheckboxListField/CheckboxListField';
import TableCarField from '@/components/pkomain/TableCarField/TableCarField';
import TableDriverField from '@/components/pkomain/TableDriverField/TableDriverField';
import {ROLES} from '@/store/user/enums';
export default {
  name: 'CounterpartyDetailValues',
  components: {
    StaticField,
    SelectMultiple,
    FileFieldMultiple,
    TableCarField,
    TableDriverField,
    RadioListField,
    CheckboxListField,
  },
  props: {
    counterpartyId: { type: String },
    code: { type: String },
    type: { type: String },
    values: { type: Object },
    options: { type: Array },
    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    rejections: { type: Object },
    viewed: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      rules: {
        required: [
          v => !!v || 'Обязательное поле',
        ]
      },
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkomain');
    },
  },
  methods: {
    ...mapActions({
      setViewedQuestions: 'pkomain/setViewedQuestions',
    }),
    onViewed(code) {
      this.setViewedQuestions({
        id: this.counterpartyId,
        questions: {
          [code]: !this.viewed[code]
        }
      });
    },
  },
}
</script>

<style module lang="scss">
.root {}
</style>
