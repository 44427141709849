<template>
  <div :class="$style.root">
    <div v-for="(row, index) in value" :class="$style.item" :key="index">
      <div class="d-flex mt-3">
        <div class="flex-grow-1">
          <div :class="$style.row">
            <div
                v-for="{value, text} in [
                  {value: 'driverFio', text: 'ФИО водителя'},
                  {value: 'driverLicenseCategory', text: 'Категория водительских прав'},
                ]"
                :class="$style.col"
                :key="value"
            >
              <div :class="$style.head">{{text}}</div>
              <template v-if="readonly">
                <StaticField :value="row.additional[value]" />
              </template>
              <template v-else>
                <DateInput v-if="~value.indexOf('Date')" v-model="row.additional[value]" />
                <VTextField v-else v-model="row.additional[value]" outlined dense auto-grow rows="1" hideDetails="auto" />
              </template>
            </div>
          </div>
          <div :class="$style.row">
            <div
                v-for="{value, text} in [
                  {value: 'driverLicenseDateEnd', text: 'Даты водительских прав (до)'},
                  {value: 'driverMedicalCheckupDateEnd', text: 'Даты медосмотра водителя (до)'},
                  {value: 'dopogDateEnd', text: 'Дата свидетельства ДОПОГ (до)'},
                ]"
                :class="$style.col"
                :key="value"
            >
              <div :class="$style.head">{{text}}</div>
              <template v-if="readonly">
                <StaticField :value="row.additional[value]" />
              </template>
              <template v-else>
                <DateInput v-if="~value.indexOf('Date')" v-model="row.additional[value]" />
                <VTextField v-else v-model="row.additional[value]" outlined dense auto-grow rows="1" hideDetails="auto" />
              </template>
            </div>
          </div>
          <div :class="$style.row">
            <div
                v-for="{value, text} in [
                  {value: 'documents', text: 'Документы'},
                ]"
                :class="$style.col"
                :key="value"
            >
              <div :class="$style.head">{{text}}</div>
              <FileFieldMultiple v-model="row.additional[value]" :readonly="readonly" add-file-label="Добавить файл .pdf" />
            </div>
          </div>
        </div>
        <div v-if="value.length > 1 && !readonly && !(isReviewer || isSpectator)" class="flex-grow-0 pl-3 pt-5">
          <VBtn fab dark small color="primary" depressed outlined @click="onDelete(index)">
            <VIcon small dark v-text="'mdi-close'" />
          </VBtn>
        </div>
        <div v-if="isReviewer || isSpectator" class="flex-grow-0 pl-3 pt-5">
          <VBtn v-if="viewed[getCodeForView({code, id: row.id})]" class="mr-2" depressed color="success" outlined small @click="() => onViewed(getCodeForView({code, id: row.id}))">
            <VIcon small dark>mdi-eye-check-outline</VIcon>
          </VBtn>
          <VBtn v-else class="mr-2" depressed color="light" small @click="() => onViewed(getCodeForView({code, id: row.id}))">
            <VIcon small dark>mdi-eye</VIcon>
          </VBtn>
        </div>
      </div>
    </div>
    <div :class="$style.footer">
      <template v-if="isReviewer || isSpectator">
        <VBtn class="float-right ml-2" depressed small @click="() => getLink('excel')">Скачать .xls</VBtn>
        <VBtn class="float-right ml-2" depressed small @click="() => getLink('zip')">Скачать .zip</VBtn>
      </template>
      <VBtn v-if="!readonly && !(isReviewer || isSpectator)" depressed small color="primary" @click="onPush">Добавить</VBtn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/store/pkomain/api';
import DateInput from '@/components/general/DateInput/DateInput';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
import StaticField from '@/components/general/StaticField/StaticField';
import { ROLES } from '@/store/user/enums';
export default {
  name: 'TableDriverField',
  components: {
    DateInput,
    FileFieldMultiple,
    StaticField,
  },
  props: {
    code: { type: String },
    value: { type: Array },
    readonly: { type: Boolean },
    viewed: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
    }),
    id() {
      return this.$route.params.counterparty;
    },
    isReviewer() {
      return this.hasRole(ROLES.REVIEWER, 'pkomain');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkomain');
    },
  },
  methods: {
    getLink(format) {
      const id = this.id;
      const code = this.code;
      Api.downloadTable({ format, id, code });
    },
    onPush() {
      this.$emit('input', [
        ...this.value,
        {
          id: '',
          type: 'TABLE_DRIVER',
          value: '',
          additional: {
            driverFio: '',
            driverLicenseCategory: '',
            driverLicenseDateEnd: '',
            driverMedicalCheckupDateEnd: '',
            dopogDateEnd: '',
            documents: [],
          }
        },
      ]);
    },
    onDelete(index) {
      const next = JSON.parse(JSON.stringify(this.value));
      next.splice(index, 1);
      this.$emit('input', next);
    },
    onViewed(code) {
      this.$emit('question-viewed', code);
    },
    getCodeForView({ code, id }) {
      return `${code}.${id}`;
    },
  }
}
</script>

<style module lang="scss">
.root{}
.head{
  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}
.item {
  margin: 0 -24px;
  padding: 16px 24px;
  &:first-child {
    padding-top: 0;
  }
  &:nth-child(2n) {
    background: #eeeeee;
  }
}
.row{
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.col{
  width: 14.28571428571429%;
  min-width: 220px;
  flex: 1 1 auto;
  padding: 5px;
}
.body{}
.footer {
  padding-top: 10px;
}
</style>
